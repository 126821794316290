<template>
  <section id="testimonials" class="testimonial-section">
    <div class="container">
      <h2>
        What people say about Miracle
        <span>
          Apparently we had reached a great height in the atmosphere, for the
          sky was a dead black, and the stars
        </span>
      </h2>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide
          v-for="(testimonial_info, index) in testimonial_obj"
          :key="index"
        >
          <div class="testimonial-contents">
            <div class="testimonial-image">
              <img :src="testimonial_info.image" alt />
            </div>
            <div class="testimonial-texts">
              <p>
                {{ testimonial_info.description }}
                <!-- Apparently we had reached a great height in the
                atmosphere, for the sky was a dead black, and the stars had
                ceased to twinkle. By the same illusion which lifts the horizon
                of the sea to the level of the spectator on a hillside,
                the sable cloud beneath was dished out, and the car seemed
                to float in the middle of an immense dark sphere, whose upper
                half was strewn with silver. Looking down into the dark gulf
                below, I could see a ruddy light streaming through a rift in the clouds. -->
              </p>
              <span class="testimonial-name">
                - {{ testimonial_info.name }}
              </span>
              <div class="star-rating">
                <fa-icon icon="user-secret" />
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star"></span>
                <span class="fa fa-star"></span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <TestimonialContent/>
        </swiper-slide>
        <swiper-slide>
          <TestimonialContent/>
        </swiper-slide> -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import TestimonialContent from "./TestimonialContent.vue";

export default {
  name: "swiper-example-pagination-dynamic",
  title: "Pagination / Dynamic bullets",
  components: {
    Swiper,
    SwiperSlide,
    TestimonialContent,
  },

  computed: {
    ...mapState(["testimonial_obj"]),
  },
  created() {
    this.listTestimonial();
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
      },
    };
  },
  methods: {
    ...mapActions(["listTestimonial"]),
  },
};
</script>
