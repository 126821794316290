<template>
  <div class="testimonial-contents">
    <div class="testimonial-image">
      <img src="../assets/images/munna-bhai-759@2x.png" alt />
    </div>
    <div class="testimonial-texts">
      <p>
        Apparently we had reached a great height in the
        atmosphere, for the sky was a dead black, and the stars had
        ceased to twinkle. By the same illusion which lifts the horizon
        of the sea to the level of the spectator on a hillside,
        the sable cloud beneath was dished out, and the car seemed
        to float in the middle of an immense dark sphere, whose upper
        half was strewn with silver. Looking down into the dark gulf
        below, I could see a ruddy light streaming through a rift in the clouds.
      </p>
      <span class="testimonial-name">
        - Muraliprasad Hariprasad Sharma
      </span>
      <div class="star-rating">
        <fa-icon icon="user-secret"/>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star checked"></span>
        <span class="fa fa-star"></span>
        <span class="fa fa-star"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialContent',
};
</script>

<style>
</style>
