<template>
  <article class="welcome-card">
    <h3>
      About Us
    </h3>
    <p>
    {{about_obj.description}}
    </p>
  </article>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'WelcomeCard',

  computed: {
    ...mapState(["about_obj"])
  },
  created() {
    this.listAbout();
  },
  methods: {
    ...mapActions(["listAbout"]),
  }

};
</script>

<style>

</style>
