<template>
  <section id="services" class="services-section">
    <div class="container">
      <h2>
        Welcome to Miracle View Resort
        <span>
          Apparently we had reached a great height in the atmosphere, for the
          sky was a dead black, and the stars
        </span>
      </h2>
      <div class="services-cards">
        <article
          class="services-card"
          v-for="(service_obj, index) in service_info_obj"
          :key="index"
        >
          <div class="services-image">
          <img src="../assets/images/undraw_booking.svg" alt="" />
          </div>
          <h3>
            {{ service_obj.name }}
          </h3>
          <p>
            {{ service_obj.description }}
          </p>
        </article>
        <!-- <ServicesCard/> -->
        <!-- <ServicesCard/>
        <ServicesCard/> -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import ServicesCard from './ServicesCard.vue';

export default {
  name: "ServicesSection",
  components: {
    // ServicesCard,
  },

  computed: {
    ...mapState(["service_info_obj"]),
  },
  created() {
    this.listServiceInfo();
  },
  methods: {
    ...mapActions(["listServiceInfo"]),
  },
};
</script>

<style>
</style>
