<template>
  <section id="portfolio" class="room-types">
    <div class="container">
      <h2>
        Welcome to Miracle View Resort
        <span>
          Apparently we had reached a great height in the atmosphere, for the
          sky was a dead black, and the stars
        </span>
      </h2>
      <RoomTypesTabs
        :tabs="tabs"
        :initialTab="initialTab"
        class="tabs-button-area"
      >
        <span slot="tab-head-all" class="tabs-button"> All </span>
        <template slot="tab-panel-all">
          <div
            class="room-cards"
            v-for="(room_type, index) in room_type_obj"
            :key="index"
          >
            <span
              class="room-card"
              v-for="(room_obj, index) in room_type.rooms"
              :key="index"
            >
              <img :src="room_obj.image" alt="" />
            </span>
          </div>
        </template>

        <span slot="tab-head-deluxe" class="tabs-button"> Deluxe Room </span>
        <template slot="tab-panel-deluxe">
          <div
            class="room-cards"
            v-for="(room_type, index) in room_type_obj"
            :key="index"
            v-if="room_type.name.toLowerCase() == 'deluxe'"
          >
            <div
              class="room-card"
              v-for="(room_obj, index) in room_type.rooms"
              :key="index"
            >
              <img :src="room_obj.image" alt="" />
            </div>
          </div>
        </template>

        <span slot="tab-head-family" class="tabs-button"> Family Room </span>
        <template slot="tab-panel-family">
          <div
            class="room-cards"
            v-for="(room_type, index) in room_type_obj"
            :key="index"
            v-if="room_type.name.toLowerCase() == 'family'"
          >
            <div
              class="room-card"
              v-for="(room_obj, index) in room_type.rooms"
              :key="index"
            >
              <img :src="room_obj.image" alt="" />
            </div>
          </div>
        </template>

        <span slot="tab-head-single" class="tabs-button"> Single Room </span>
        <template slot="tab-panel-single">
          <div
            class="room-cards"
            v-for="(room_type, index) in room_type_obj"
            :key="index"
            v-if="room_type.name.toLowerCase() == 'single'"
          >
            <div
              class="room-card"
              v-for="(room_obj, index) in room_type.rooms"
              :key="index"
            >
              <img :src="room_obj.image" alt="" />
            </div>
          </div>
        </template>
      </RoomTypesTabs>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import TabsButton from './TabsButton.vue';
import RoomTypesTabs from "./RoomTypesTabs.vue";

export default {
  name: "RoomTypesSection",
  // components: {
  //   TabsButton,
  //   RoomTypesCard,
  // },
  components: {
    RoomTypesTabs,
  },

  computed: {
    ...mapState(["room_type_obj"]),
  },

  watch: {
    room_type_obj: function () {
      if (this.room_type_obj.length > 0) {
        var formattedRecord1 = [];
        var formattedRecord2 = [];
        formattedRecord1.push("all");
        formattedRecord2.push({ room_type: "all" });
        this.$store.state.room_type_obj.forEach(function (rec) {
          formattedRecord1.push(rec.name.toLowerCase());
          formattedRecord2.push({ room_type: rec.name.toLowerCase() });
        });
        this.tabs = formattedRecord1;
        this.room_tabs = formattedRecord2;
      }
    },
  },

  created() {
    this.listRoom();
  },

  data() {
    return {
      initialTab: "all",
      tabs: [],
      // tabs: ['all', 'deluxe', 'family'],
      room_tabs: [],
    };
  },

  methods: {
    ...mapActions(["listRoom"]),
  },
};
</script>

<style>
</style>
