<template>
  <main id="app">
    <div class="vld-parent">
      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    </div>

    <router-view v-if="isLoading == false" />
  </main>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      banner_image_name: "",
      start_loading: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapState(["banner_obj", "banner_image"]),
  },
  created() {
    this.listBanner();
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.setBannerImage(this.banner_obj.image_url);
    }, 7000);

    this.banner_image_name = "banner_image";
  },

  // watch: {
  //
  //   start_loading: function(){
  //     if(this.start_loading==true){
  //       this.isLoading = true;
  //       setTimeout(() => {
  //           this.isLoading = false;
  //           this.setBannerImage(this.banner_obj.image_url);
  //       }, 5000);
  //
  //       this.banner_image_name = "banner_image";
  //
  //
  //     }
  //   },
  // },

  methods: {
    ...mapMutations(["setBannerImage"]),
    ...mapActions(["listBanner"]),
  },
};
</script>

<style lang="sass">
@import './assets/sass/style.sass'

#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

#nav
  padding: 30px

  a
    font-weight: bold
    color: #2c3e50

    &.router-link-exact-active
      color: #42b983
</style>
