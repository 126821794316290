export default {
  'title': '** Miracle Hotel **',
  'token': window.localStorage.getItem("token"),
  'loader': true,
  'banner_image': window.localStorage.getItem("banner_image"),
  'banner_obj': [],
  'about_obj': [],
  'service_info_obj': [],
  'room_type_obj': [],
  'success_message': "",
  'error_message': "",
  'response_error': "",
  'testimonial_obj': [],
  'gallery_obj': [],
  'package_obj': [],
}
