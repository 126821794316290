<template>
  <div class="home">
    <HomeBanner/>
    <SiteHeader/>
    <WelcomeSection/>
    <RoomTypesSection/>
    <Packages/>
    <TestimonialSection/>
    <Gallery/>
    <ServicesSection/>
    <BookSection/>
    <ContactSection/>
    <SiteFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue';
import HomeBanner from '@/components/HomeBanner.vue';
import WelcomeSection from '@/components/WelcomeSection.vue';
import RoomTypesSection from '@/components/RoomTypesSection.vue';
import TestimonialSection from '@/components/TestimonialSection.vue';
import ServicesSection from '@/components/ServicesSection.vue';
import Packages from '@/components/Packages.vue';
import Gallery from '@/components/Gallery.vue';
import BookSection from '@/components/BookSection.vue';
import ContactSection from '@/components/ContactSection.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'Home',
  components: {
    HomeBanner,
    SiteHeader,
    WelcomeSection,
    RoomTypesSection,
    TestimonialSection,
    ServicesSection,
    Packages,
    Gallery,
    BookSection,
    ContactSection,
    SiteFooter,
  },
};
</script>
