<template>
  <section class="gallery" id="gallery">
    <div class="container">
      <h2>Gallery</h2>
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(gallery, index) in gallery_obj" :key="index">
          <div class="image">
            <img :src="gallery.image" />
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <div class="image">
            <img src="../assets/images/kuri.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="image">
            <img src="../assets/images/package.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="image">
            <img src="../assets/images/package.jpg" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="image">
            <img src="../assets/images/package.jpg" />
          </div>
        </swiper-slide> -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- <div>
        <a href="#"> See more </a>
      </div> -->
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-example-pagination-dynamic",
  title: "Pagination / Dynamic bullets",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(["gallery_obj"]),
  },
  created() {
    this.listGallery();
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["listGallery"]),
  },
};
</script>

<style scoped lang="scss">
.image {
  min-height: 350px;
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}
</style>
