import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue';
import Routes from './router';
// import swiper style
import 'swiper/css/swiper.css';
import { createStore } from './store'
import Toasted from 'vue-toasted';
library.add(faBars)

Vue.component('font-awesome-icon', FontAwesomeIcon)


// library.add(faUserSecret, faSpinner);
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueAwesomeSwiper);
Vue.use(require('vue-moment'));
// Vue.component('fa-icon', fontAwesomeIcon);

Vue.use(Toasted)

const router = new VueRouter({
  routes: Routes,
  mode: 'history',
});

const store = createStore();

new Vue({
  store,
  router: router,
  // PulseLoader,
  render: (h) => h(App),
}).$mount('#app');
