<template>
  <section id="about" class="welcome-section">
    <div class="container">
      <h2>
        Welcome to Miracle View Resort
        <span>
          Apparently we had reached a great height in the atmosphere, for the
          sky was a dead black, and the stars
        </span>
      </h2>
      <div class="welcome-cards">
        <article
          class="welcome-card"
          v-for="(about_obj_content, index) in about_obj"
          :key="index"
        >
          <h3>
            {{ about_obj_content.name }}
          </h3>
          <p>
            {{ about_obj_content.description }}
          </p>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WelcomeCard from "./WelcomeCard.vue";

export default {
  name: "WelcomeSection",
  components: {
    WelcomeCard,
  },
  computed: {
    ...mapState(["about_obj"]),
  },
  created() {
    this.listAbout();
  },
  methods: {
    ...mapActions(["listAbout"]),
  },
};
</script>

<style>
</style>
