export default {


    setToken(state, token) {
        state.token = token;
    },

    setLoader(state, loader) {
        state.loader = loader;
    },

    setBannerImage(state, banner_image) {
        state.banner_image = banner_image;
    },

    setBannerList(state, banner_obj) {
        state.banner_obj = banner_obj;
    },

    setAboutList(state, about_obj) {
        state.about_obj = about_obj;
    },

    setServiceInfoList(state, service_info_obj) {
        state.service_info_obj = service_info_obj;
    },


    setRoomList(state, room_type_obj) {
        state.room_type_obj = room_type_obj;
    },


    setSuccessMessage(state, success_message) {
        state.success_message = success_message;
    },

    setErrorMessage(state, error_message) {
        state.error_message = error_message;
    },


    setMessage(state, response_error) {
        state.response_error = response_error;
    },

    setTestimonialList(state, testimonial_obj) {
        state.testimonial_obj = testimonial_obj;
    },

    setGalleryList(state, gallery_obj) {
        state.gallery_obj = gallery_obj;
    },

    setPackageList(state, package_obj) {
        state.package_obj = package_obj;
    },


}
