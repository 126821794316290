<template>
  <form class="book-form" v-on:submit.prevent="bookingForm">
    <div class="form-room-details">
      <div class="name">
        <label for="name"> Full Name </label>
        <input type="name" id="name" v-model="full_name" placeholder="Enter Ful Name" required />
      </div>


      <div class="name">
        <label for="name"> Email </label>
        <input type="email" id="name" v-model="email" placeholder="Enter your email address" required />
      </div>



      <!-- <div class="name">
        <label for="name"> First Name </label>
        <input
          type="name"
          id="name"
          v-model="first_name"
          placeholder="Enter your First name"
        />
      </div>


      <div class="name">
        <label for="name"> Middle Name </label>
        <input
          type="name"
          id="name"
          v-model="middle_name"
          placeholder="Enter your Middle name"
        />
      </div>

      <div class="name">
        <label for="name"> Last Name </label>
        <input
          type="name"
          id="name"
          v-model="last_name"
          placeholder="Enter your Last name"
        />
      </div> -->

    </div>


    <div class="form-name">
      <!-- First Name -->
      <!-- <div class="name">
        <label for="name"> Email </label>
        <input
          type="email"
          id="name"
          v-model="email"
          placeholder="Enter your email address"
        />
      </div> -->

      <!-- Last Name -->
      <div class="name">
        <label for="lastName"> Phone Number </label>
        <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="lastName"
          v-model="phone_number" placeholder="Enter your contract number" required />
      </div>

      <div class="form-value">
        <label for="totalPeople"> Street Address </label>
        <input type="text" id="totalPeople" placeholder="Street Address.." v-model="address" required />
      </div>

    </div>

    <!-- <div class="form-room-details">
      <div class="form-value">
        <label for="totalPeople"> Street Address </label>
        <input
          type="text"
          id="totalPeople"
          placeholder="Street Address.."
          v-model="street_address"
        />
      </div> -->

    <!-- Select type of rooms -->
    <!-- <div class="form-value">
        <label for="roomTypes"> Provinces </label>
        <select name="room-types" id="roomTypes" v-model="province_selected">
          <option selected="selected" value disabled> Select Provinces </option>
          <option v-for="(province, index) in provinces" :key="index" :value="province.name"> {{province.name}} </option>
        </select>
      </div>

      <div class="form-value">
        <label for="roomTypes"> City </label>
        <select name="room-types" id="roomTypes" v-model="district_selected">
          <option selected="selected" value disabled> Select City </option>
          <option v-for="(district,index) in districts" :key="index" :value="district"> {{district}} </option>
        </select>
      </div>

      <div class="form-value">
        <label for="totalPeople"> Street Address </label>
        <input
          type="text"
          id="totalPeople"
          placeholder="Street Address.."
          v-model="street_address"
        />
      </div> -->
    <!--
    </div> -->

    <!-- Enter check-in Date -->

    <div class="form-name">
      <!-- First Name -->
      <div class="name">
        <label for="name"> Check-in Date </label>
        <input type="date" id="name" v-model="check_in_date" required />
      </div>

      <!-- Last Name -->
      <div class="name">
        <label for="lastName"> Check-out Date </label>
        <input type="date" id="lastName" v-model="check_out_date" required />
      </div>
    </div>

    <div class="form-room-details">
      <!-- Select type of rooms -->
      <div class="form-value">
        <label for="roomTypes"> Type of Package </label>
        <select name="room-types" id="roomTypes" v-model="package" required>
          <option selected="selected" value disabled> Select a Package </option>
          <option v-for="(room_type, index) in package_obj" :key="index" :value="room_type.id"> {{room_type.name}}
          </option>
        </select>
      </div>

      <!-- Enter no of people -->
      <!-- <div class="form-value">
        <label for="totalPeople"> No of People </label>
        <input
          type="text"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          id="totalPeople"
          v-model="no_of_pepole"
        />
      </div> -->

      <!-- Enter no of days -->
      <!-- <div class="form-value">
        <label for="totalDays"> No of Days </label>
        <input
          type="text"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          id="totalDays"
          v-model="no_of_day"
        />
      </div> -->
    </div>


    <button class="book-now">
      Book Now
    </button>
  </form>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import province from '../js/districts.js';
  import moment from 'moment';

  export default {
    name: 'BookForm',
    components: {
    },

    computed: {
      ...mapState(["package_obj", "success_message", "error_message", "response_error"]),

      districts: function () {
        if (this.province_selected != "") {
          return this.provinces.find(province => province.name == this.province_selected).districts
        }
        else {
          return "";
        }
      },
    },



    created() {
      this.listPackage();
    },

    data() {
      return {
        address: '',
        district_selected: '',
        province_selected: '',
        provinces: province,
        errors: [],
        full_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        check_in_date: '',
        check_out_date: '',
        package: '',
        no_of_pepole: 1,
        no_of_day: 1,
      };
    },

    methods: {
      ...mapActions(["listPackage"]),

      bookingForm() {
        this.errors = [];
        if (this.full_name == "") {
          this.errors['full_name'] = "Full Name require"
        }
        else if (this.email == "") {
          this.errors['email'] = "Email required."
        }
        else if (this.phone_number == "") {
          this.errors['phone_number'] = "Email required."
        }

        else if (this.address == "") {
          this.errors['address'] = "Street address required."
        }
        else if (this.check_in_date == "") {
          this.errors['check_in_date'] = "Check_in_date required."
        }
        else if (this.check_out_date == "") {
          this.errors['check_out_date'] = "Check_out_date required."
        }
        else {
          this.$store.dispatch("Createbooking", {
            'full_name': this.full_name,
            'email': this.email,
            'address': this.address,
            'phone_number': this.phone_number,
            'package': this.package,
            'check_in_date': moment(this.check_in_date, "YYYY-MM-DD"),
            'check_out_date': moment(this.check_out_date, "YYYY-MM-DD"),
          }).then(() => {
            if (this.success_message == 'success') {
              this.full_name = "";
              this.email = "";
              this.address = "";
              this.phone_number = "";
              this.package = "";
              this.check_in_date = "";
              this.check_out_date = "";
              this.$toasted.success("Successfully Booked your package, please conform your booking by contacting us.", {
                position: "top-center",
                duration: 7000
              });
              this.success_message = ""
            } else if (this.error_message == "errormessage") {
              this.full_name = "";
              this.email = "";
              this.address = "";
              this.phone_number = "";
              this.package = "";
              this.check_in_date = "";
              this.check_out_date = "";
              this.$toasted.error(this.response_error, {
                position: "top-center",
                duration: 5000
              });
              this.error_message = ""
              this.response_error = ""
            }
          });
        }

      }
    }
  };
</script>

<style>

</style>