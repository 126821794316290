<template>
  <header class="header">
    <div class="container container-header">
      <div class="header-contents">
        <div class="logo">
          <img src="../assets/images/logo.png" alt="Hotel Miracle Logo" />
        </div>
        <div class="menu">
          <button class="menu-icon" @click="activeDropdown = !activeDropdown">
            <font-awesome-icon icon="bars" />
          </button>
          <nav v-show="activeDropdown">
            <ul>
              <li>
                <a href="/#home">Home</a>
              </li>
              <li>
                <a href="/#about">About</a>
              </li>
              <li>
                <a href="/#portfolio">Room</a>
              </li>
              <li>
                <a href="/#packages">Packages</a>
              </li>
              <li>
                <a href="/#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="/#gallery">Gallery</a>
              </li>
              <li>
                <a href="/#services">Services</a>
              </li>
              <li>
                <a href="/#booking">Booking</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      activeDropdown: true,
      window: {
        width: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      if (this.window.width < 760) {
        this.activeDropdown = false;
      }
    },
  },
};
</script>

<style>
</style>
