<template>
  <section class="packages" id="packages">
    <div class="container">
      <h2>Our Packages</h2>
      <div class="group-images">
        <div class="image-card" v-for="item in package_obj" :key="item.title">
          <img id="package_img" src="img/miracle_package.jpg" />
          <div class="card-content">
            <div class="discount" v-if="item.discount > 0">
              <s> Rs.{{ Math.trunc(item.price) }}/-</s>Rs.{{
                Math.trunc(item.amount_dict)
              }}/-
            </div>
            <div class="discount" v-else>Rs.{{ Math.trunc(item.price) }}/-</div>

            <div>
              <h3>{{ item.name }}</h3>
              <p class="card-subtitle">
                <strong>{{ item.sub_title }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["package_obj"]),
  },
  created() {
    this.listPackage();
  },
  data() {
    return {
      packages: [
        {
          img: "../assets/images/banner.png",
          title: "Deluxe Package",
          subtitle: "Great value deal on your finger print",
          discount: "40%",
          // price: "5999",
        },
        {
          img: "../assets/images/banner.png",
          title: "Deluxe Package",
          subtitle: "Great value deal on your finger print",
          discount: "4%",
          // price: "9999",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["listPackage"]),
  },
};
</script>

<style lang="css">
#package_img {
  opacity: 0.8;
}
</style>