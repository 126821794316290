<template>
  <a href="/#booking">
    <span class="book-now">Book Now</span>
  </a>
</template>

<script>
export default {

};
</script>

<style>

</style>
