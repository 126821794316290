<template>
  <footer class="site-footer">
    <div class="container">
      <span>
        Designed by <a href="http://ghanashyamkhadka.com.np" target="_blank"> Ghana Shyam</a> and Developed by <a href="http://kraut.com.np" target="_blank"> Kapin</a> & <a href="http://kprabin.com.np" target="_blank"> Prabin</a>
      </span>
      <span class="copyright-text">
        © Miracle View Resort 2020
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
};
</script>

<style>

</style>
