const axios = require('axios');

export default {

  listBanner({ commit }) {
    axios.get('https://hotelmiracle.herokuapp.com/api/v1/banner', {})
      .then(response => {
        commit('setBannerList', response.data)
      })
  },

  listAbout({ commit }) {
    axios.get('https://miracleresort.pythonanywhere.com/v1/abouts/', {})
      .then(response => {
        commit('setAboutList', response.data)
      })
  },


  listServiceInfo({ commit }) {
    axios.get('https://miracleresort.pythonanywhere.com/v1/services/', {})
      .then(response => {
        commit('setServiceInfoList', response.data)
      })
  },

  listRoom({ commit }) {
    axios.get('https://miracleresort.pythonanywhere.com/v1/rooms/', {})
      .then(response => {
        commit('setRoomList', response.data)
      })
  },

  Createbooking({ commit }, booking_obj) {
    axios.post('https://miracleresort.pythonanywhere.com/v1/booking/', booking_obj)
      .then(response => {
        if (response.status == 201) {
          commit("setSuccessMessage", "success");
        } else {
          commit("setErrorMessage", 'errormessage')
          commit("setMessage", error.response.data.message);
        }
      })
      .catch(error => {
        if (error) {
          commit("setErrorMessage", 'errormessage')
          commit("setMessage", error.response.data.message);
        }
      })
  },

  listTestimonial({ commit }) {
    axios.get('https://miracleresort.pythonanywhere.com/v1/testimonials/', {})
      .then(response => {
        commit('setTestimonialList', response.data)
      })
  },

  listGallery({ commit }) {
    axios.get('https://miracleresort.pythonanywhere.com/v1/gallerys/', {})
      .then(response => {
        commit('setGalleryList', response.data)
      })
  },

  listPackage({ commit }) {
    axios.get('https://miracleresort.pythonanywhere.com/v1/packages/', {})
      .then(response => {
        commit('setPackageList', response.data)
      })
  },



};
