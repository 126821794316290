<template>
  <section id="booking" class="book-section">
    <div class="container">
      <h2>
        Book a Room
        <span>
          Apparently we had reached a great height in the atmosphere,
          for the sky was a dead black, and the stars
        </span>
      </h2>
      <div class="form-section">
        <div class="services-image">
          <img src="../assets/images/undraw_click_here.svg" alt="" />
        </div>
        <BookForm/>
      </div>
    </div>
  </section>
</template>

<script>
import BookForm from './BookForm.vue';

export default {
  name: 'BookSection',
  components: {
    BookForm,
  },
};
</script>

<style>

</style>
