<template>
  <section class="contact-section">
    <div class="container">
      <div class="contact-wrap">
        <div class="social-media">
          <a href="https://www.facebook.com/miracleviewresort" target="_blank">
            <font-awesome-icon
              :icon="{ prefix: 'fab', iconName: 'facebook' }"
            />
          </a>
          <a href="twitter.com" target="_blank">
            <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" />
          </a>
          <a
            href="https://www.instagram.com/miracleviewresort_kalinchwok/"
            target="_blank"
          >
            <font-awesome-icon
              :icon="{ prefix: 'fab', iconName: 'instagram' }"
            />
          </a>
        </div>
        <address>
          <!-- <span>Miracle View Resort Pvt. Ltd.</span> -->
          <span><strong class="change-holidays-title">ADDRESS</strong></span>
          <span><strong>kalinchowk, Kuri Village</strong></span>

          <span><strong>Dolakha, Nepal</strong></span>
          <span>miracleviewresortkuri@gmail.com</span>
          <span><strong>+977-9808990561</strong></span>
        </address>

        <div id="pk">
          <address>
            <!-- <span>Miracle View Resort Pvt. Ltd.</span> -->
            <span
              ><strong class="change-holidays-title"
                >RESERVATIONS
              </strong></span
            >
            <a href="https://changeholidaysplanner.com/" target="_blank"
              ><span><strong> Change Holidays Planner</strong></span></a
            >
            <span
              ><strong
                >Battisputali Road (Opposite of Dwarika's Hotel),
                Kathmandu</strong
              ></span
            >

            <span>info@changeholidaysplanner.com</span>
            <span><strong>01-5906793, +977-9851199516</strong></span>
          </address>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/fontawesome-free-brands";
library.add(faFacebook, faTwitter, faInstagram);

export default {
  name: "ContactSection",
};
</script>

<style>
#pk {
  padding-left: 20px;
}
@media only screen and (max-width: 767px) {
  /*Big smartphones [426px -> 600px]*/
  #pk {
    padding-left: 0px;
    padding-top: 20px;
  }
}
</style>