<template>
  <section id="home" class="home-banner">
  <!-- <section id="home-banner" class="home-banner" v-bind:style="{ 'background-image': 'url(' + banner_image + ')' }"> -->
    <video muted autoplay loop>
      <source src="../assets/videos/miracle.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="overlay-contents">
      <h1>
        <span>
          {{banner_obj.banner_text}}
        </span>
        <strong>
        {{banner_obj.banner_title}}
        </strong>
      </h1>
      <address>
        {{banner_obj.address}}
      </address>
      <BookNowButton/>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BookNowButton from './BookNowButton.vue';

export default {
  name: 'HomeBanner',
  components: {
    BookNowButton,
  },
  computed: {
    ...mapState(["banner_obj", "banner_image"])
  },
  created() {
    this.listBanner();
  },
  // mounted: function () {
  //   document.getElementById("home").style.backgroundImage = 'url(' + this.banner_image + ')';
  //   },
  methods: {
    ...mapActions(["listBanner"]),
  }
};
</script>

<style>

</style>
